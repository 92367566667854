.error-container {
  font-size: 13px;
  color: #fff;
  font-weight: 300;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  height: 100vh;
}
