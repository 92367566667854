.error-404-header {
  font-size: 2rem;
  padding-top: 35px;
  color: #fff;

  .error-404-code {
    font-size: 9rem;
    color: rgba(255, 213, 1, 0.9);
  }

  .error-404-text {
    font-size: 4.5rem;
  }

  .error-404-description {
    font-weight: 500;
    margin-top: 15px;
    font-size: 2rem;
  }  

  .error-404-custom {
    margin-top: 20%;
    white-space: normal;
    word-break:break-all;
    padding-left: 90px;
    padding-right: 90px;
  }
}



